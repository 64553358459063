import React, { Fragment } from 'react';
import { Box } from 'theme-ui';
import { nanoid } from 'nanoid';
import styled from '@emotion/styled';
const Table = styled.table`
  width: 50%;
  text-align: center;
  white-space: pre;
  line-height: 1.3;
  margin: 20px 0;
  thead {
    font-weight: bold;
  }
  tr {
    font-size: 0.8em;
  }
  td {
    padding: 0.5em;
    border-bottom: 1px solid #ececec;
    color: #666;
    a {
      color: black !important;
    }
    &:last-child {
      padding-right: 0;
    }
  }
`;

const styles = {
  container: {
    display: `flex`,
    justifyContent: `center`,
  },
  underlined: {
    width: `100%`,
    borderBottom: `1px solid #ececec`,
    textAlign: `center`,
    paddingBottom: `1.3em`,
  },
  leftCentered: {
    textAlign: `left`,
    margin: 0,
  },
};

export default function ConvertTextToPricingComponent({
  content,
  marginX,
  marginY,
}) {
  return processText(content, marginX, marginY);
}
const processText = (content, marginX, marginY) => {
  return (
    <div>
      {content.map(({ link, type, value, child }) => {
        return (
          <Box key={nanoid()} px={marginX || 0} py={marginY || 0}>
            {['table', 'dot', 'number'].includes(type) ? (
              type === 'table' ? (
                renderTable({ value, child })
              ) : (
                <div></div>
              )
            ) : (
              <Fragment>
                {renderText({ value, type })}
                {child && processText(child, marginX, marginY)}
              </Fragment>
            )}
          </Box>
        );
      })}
    </div>
  );
};

const renderTable = ({ value, child }) => {
  return (
    <div style={styles.container}>
      <Table>
        <thead>
          <tr>
            {value.map((item) => (
              <td>{item}</td>
            ))}
          </tr>
        </thead>
        <tbody>
          {child.map((item) => (
            <tr>
              {item.child.map(({ type, value }) => {
                const rowspanValue = type?.split('-')?.[1] || 1;
                return (
                  <td rowSpan={rowspanValue}>
                    <span>{renderText({ value, type: 'text' })}</span>
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};
const renderText = ({ value, type }) => {
  return value?.map((item) => {
    const newValue = decodeText(item);
    const htmlText = newValue && convertToText(newValue);
    return wrapText({ type, htmlText });
  });
};
// this functions convert "type" to html tags
const wrapText = ({ type, htmlText }) => {
  switch (type) {
    case 'h1':
      return <h1>{htmlText}</h1>;
    case 'h2':
      return <h2>{htmlText}</h2>;
    case 'h3':
      return <h3>{htmlText}</h3>;
    case 'h4':
      return <h4>{htmlText}</h4>;
    case 'h5':
      return <h5>{htmlText}</h5>;
    case 'h6':
      return <h6>{htmlText}</h6>;
    case 'dot':
    case 'number':
      return <li>{htmlText}</li>;
    case 'bold':
      return <strong>{htmlText}</strong>;
    case 'table':
      return <table>{htmlText}</table>;
    case 'underlined':
      return <p style={styles.underlined}>{htmlText}</p>;
    case 'leftCentered':
      return <p style={styles.leftCentered}>{htmlText}</p>;
    default:
      return htmlText;
  }
};

// this function converts inner styles to an object
// strong|this part will be bold
// link(www.togetherprice.com)|this part will be a link with href="http://www.togetherprice.com"
// more choice can be added
const decodeText = (text: string) => {
  const index = text.indexOf('|');
  if (index !== -1) {
    const values = text.split('|');
    if (values[0].substring(0, 4) === 'link') {
      const link = values[0].substring(
        (values[0]?.indexOf('(') || 0) + 1,
        values[0].indexOf(')') || values[0].length
      );
      return { type: 'link', href: link, value: values[1] };
    } else if (values[0].substring(0, 6) === 'strong') {
      return { type: 'strong', value: values[1] };
    } else if (values[0].substring(0, 4) === 'line') {
      return { type: 'line', value: values[1] };
    }
  } else {
    return { type: 'text', value: text };
  }
};

// this function converts objects to html tags
const convertToText = ({
  type,
  value,
  href,
}: {
  type: string;
  value: string;
  href?: string;
}) => {
  switch (type) {
    case 'text':
      return <span>{value} </span>;
    case 'link':
      return (
        <a
          style={{ color: 'blue' }}
          href={href}
          target='_blank'
          rel='noopener noreferrer'
        >
          {value}{' '}
        </a>
      );
    case 'line':
      return (
        <>
          <br style={{ marginBottom: '1rem' }} />
          <span>{value} </span>
        </>
      );
    case 'strong':
      return <strong>{value} </strong>;
  }
};
